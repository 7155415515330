import moment from "moment";
import { Timezone } from "./timezone";
import lang from "./langHelper";
import { Col, Form, Radio, Row } from "antd";
import { useState } from "react";
import { useAppContext } from "../context/AppContext";
export const getFileExtension = (url) => {
  // Get the last part of the URL after the last '/'
  const filename = url.substring(url.lastIndexOf("/") + 1);

  // Get the file extension by getting the last part of the filename after the last '.'
  const extension = filename.substring(filename.lastIndexOf(".") + 1);

  return extension;
};

export const isObjectEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key]) {
      if (obj[key] === '{"min":0,"max":20000000}') {
      } else {
        return false;
      }
    }
  }
  return true;
};

export function formatDate(date) {
  const now = moment();
  const inputDate = moment(date);

  if (now.isSame(inputDate, "day")) {
    return "Today, " + inputDate.format("hh:mm A");
  } else if (now.subtract(1, "day").isSame(inputDate, "day")) {
    return "Yesterday, " + inputDate.format("hh:mm A");
  } else {
    return inputDate.format("DD/MM/YYYY, hh:mm A");
  }
}

export function formatPhone(countryCode, phoneNumber) {
  const numericPhoneNumber = phoneNumber.replace(/\D/g, "");
  if (countryCode && numericPhoneNumber) {
    const groups = numericPhoneNumber.match(/(\d{2})(\d{3})(\d{3})(\d+)/);
    if (groups) {
      return `+${countryCode}-${groups[1]}-${groups[2]}-${groups[3]}-${groups[4]}`;
    }
  }
  return phoneNumber;
}

export const capitalize = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const removeNullValues = (obj) => {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
  return obj;
};

export const dateString = (created_at, format = "LLL") => {
  console.log("Client TimeZone", Timezone);
  if (!created_at) return;
  return moment(created_at).tz(Timezone).format(format);
};

export const getRandomColor = (index) => {
  const color = [
    "#a9de03",
    "#52e2f8",
    "#cb04b6",
    "#da2deb",
    "#06d6bd",
    "#bb3913",
    "#2026c8",
    "#9d8252",
    "#8b121a",
    "#0d7047",
    "#04acc2",
    "#852472",
    "#ea2c43",
    "#53d965",
    "#de5867",
    "#ac1c11",
    "#0a4c9e",
    "#170232",
    "#f04b3f",
    "#5f0044",
  ];
  // Generate random values for red, green, and blue components
  return color[index];

  var r = Math.floor(Math.random() * 256);
  var g = Math.floor(Math.random() * 256);
  var b = Math.floor(Math.random() * 256);

  // Convert decimal values to hexadecimal
  var hexR = r.toString(16).padStart(2, "0");
  var hexG = g.toString(16).padStart(2, "0");
  var hexB = b.toString(16).padStart(2, "0");

  // Concatenate and return the hex color code
  return "#" + hexR + hexG + hexB;
};


export const handleServiceAttr = (
  attributes,
  showInfoCol,
  serviceAttributes
) => {
  let mappedAttributes = [];
  return (mappedAttributes = Object.keys(attributes)?.reduce((acc, key) => {
    let attribute = serviceAttributes?.find((attr) => {
      if (attr?.type !== "package") {
        //  console.log(attr,"attr")
        return attr?.name === key;
      }
      return attr?.options?.some((option) => option?.name === key);
    });

    // console.log(attribute,"attributes----")
    const value = attributes[key];

    let ar_value;
    if (Array.isArray(value)) {
      ar_value = value.map((val) => {
        const option = attribute?.options?.find((item) => item?.name === val);
        return option?.ar_name || val;
      });
    } else {
      ar_value = attribute?.options?.find((item) => item?.name === value)?.ar_name ||  value;
    }

    if (!attribute) {
      return acc;
    }

    if (
      attribute?.type === "package" &&
      acc?.some((item) => item.name === attribute?.name)
    ) {
      return acc;
    }

    if (attribute?.type === "button") {
      acc.push({
        name: key,
        ar_name: attribute?.ar_name,
        value: showInfoCol[key]?.show    ? showInfoCol[key]?.show : null,
        ar_value: showInfoCol[key]?.show ? showInfoCol[key]?.show : null,
        type: attribute?.type,
        _id: attribute?._id,
        info: showInfoCol[key]?.info || "",
      });
    } else if (attribute?.type === "file") {
      acc.push({
        name: key,
        ar_name: attribute?.ar_name,
        type: attribute?.type,
        _id: attribute?._id,
        value: value?.length ? value?.[0]?.url : "",
        ar_value: value?.length ? value?.[0]?.url : "",
      });
    } else if (attribute?.type === "boolean") {
      acc.push({
        name: key,
        ar_name: attribute?.ar_name,
        type: attribute?.type,
        _id: attribute?._id,
        value: value === true ? value : value,
        ar_value: value === true ? value : value,
      });
    } else if (attribute?.type === "calendar") {
      acc.push({
        name: key,
        ar_name: attribute?.ar_name,
        value: value ? moment(value).format("DD-MM-YYYY") : value,
        ar_value: value ? moment(value).format("DD-MM-YYYY") : value,
        type: attribute?.type,
        _id: attribute?._id,
      });
    } else if (attribute?.type === "package") {
      const packageOptions = attribute?.options?.map((option) => ({
        option: option?.name,
        tags: attributes[option?.name] || [],
      }));

      acc.push({
        name: attribute?.name,
        ar_name: attribute?.ar_name,
        value: packageOptions,
        ar_value: packageOptions,
        type: attribute?.type,
        _id: attribute?._id,
      });
    } else {
      acc.push({
        name: key,
        ar_name: attribute?.ar_name,
        value: value,
        ar_value: ar_value ?? value,
        type: attribute?.type,
        _id: attribute?._id,
      });
    }

    return acc;
  }, []));
};

export const ColorCircleComponent = ({ color }) => {
  const circleStyle = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: color,
    display: "inline-block",
    marginLeft: "10px",
  };

  return <span style={circleStyle}></span>;
};

export const ShowAttrData = (data) => {

  const {language} = useAppContext()

  const showName = (value)=>{
    return language !== "en" && language !== null ? value?.[`${language}_name`] ??   value?.name : value?.name
   }

   const showAttrValue = (item)=>{
    return language !== "en" && language !== null ? item?.[`${language}_value`] ??   item?.value : item?.value
   }

  return (
    <Row gutter={[12, 12]}>
    {data?.attributes
      ?.filter(
        (attribute, index, self) =>
          attribute?._id !== null &&
          attribute?.type !== null &&
          attribute?.value !== null &&
          attribute?.value !== false &&
          attribute?.value !== undefined &&
          attribute?.value !== "" &&
          !(
            Array.isArray(attribute?.value) &&
            attribute?.value.length &&  
            attribute?.value[0] === false
          ) &&
          index === self.findIndex((a) => a._id === attribute?._id)
      )
      ?.map((attribute) => {
        let shouldRender = false;
        let content = null;
        if (
          attribute?.type === "button" &&
          Array.isArray(attribute?.value) &&
          attribute?.value[0] === true
        ) {
          shouldRender = true;
          content = (
            <>
              <span>
                {showName(attribute)}{" "}
                {attribute?.info ? `(${attribute?.info})` : ""}
              </span>
            </>
          );
        }

        // Handle "textbox" type
        else if (
          attribute?.type === "textbox" &&
          Array.isArray(attribute?.value) &&
          attribute?.value.length
        ) {
          shouldRender = true;
          content = (
            <>
              <span>
                {showName(attribute)}{" "}
                {attribute?.value?.length ? `(${attribute?.value[0]})` : ""}
              </span>
            </>
          );
        }

        // Handle "boolean" type
        else if (
          attribute?.type === "boolean" &&
          Array.isArray(attribute?.value) &&
          attribute?.value.length &&
          attribute?.value[0] === true
        ) {
          shouldRender = true;
          content = (
            <>
              <span>{showName(attribute)}</span>
            </>
          );
        }

        // Handle "file" or "package" types
        else if (
          attribute?.type === "file" ||
          attribute?.type === "package"
        ) {
          shouldRender = true;
          content = (
            <>
              {showName(attribute)}
            </>
          );
        }

        // Handle other types with values
        else if (Array.isArray(attribute?.value) && attribute?.value?.length) {
          shouldRender = true;
          content = (
            <>
              {showName(attribute)}{" "}
              {Array.isArray(attribute?.value) &&
                `(${showAttrValue(attribute).join(", ")})`}
            </>
          );
        }else if (!Array.isArray(attribute?.value)  && attribute?.value !== false && attribute?.value !== null && attribute?.value !== "" && attribute?.value !== undefined ) {
          shouldRender = true;
          content = (
            <>
              {showName(attribute)}{" "}
              {attribute?.value === true ? "" : `(${showAttrValue(attribute)})`}
            </>
          );
        }

        // Only render the <li> if there's valid content
        return shouldRender ? (
          <Col span={24} md={12} xxl={8} key={attribute?._id?._id}>
            <li>
              <div className="provider-list-name">{content}</div>
            </li>
          </Col>
        ) : null;
      })}
  </Row>
    
  );
};

export const ToggleRadioButton = ({ attribute }) => {
  const [value, setValue] = useState(null);

  const handleChange = (e) => {
    const newValue = e.target.value;
    console.log(newValue, "");
    setValue((prevValue) => (prevValue === newValue ? null : newValue));
  };

  return (
    <Form.Item
      name={attribute?.name}
      label={attribute?.name}
      rules={[
        {
          required: attribute?.is_required,
          message: lang(`Please select ${attribute?.name}!`),
        },
      ]}
    >
      <Radio.Group value={value} onChange={handleChange}>
        <Radio key={`${attribute?.name}-yes`} value={true}>
          {lang("Yes")}
        </Radio>
        <Radio key={`${attribute?.name}-no`} value={false}>
          {lang("No")}
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
};

export default ToggleRadioButton;
