// import { Button, message, Upload, Image } from "antd";
// import React, { useState, useEffect } from "react";
// import { UploadOutlined } from "@ant-design/icons";
// import { uploadFile } from "react-s3";
// // import { s3Config } from "../config/s3Config";
// import { ShowToast, Severty } from "../helper/toast";
// import { getFileExtension } from "../helper/functions";
// import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';


// // Only banner image uplaod 600*400

// const s3Config = {
//   region: 'us-east-1',
//   credentials: {
//     accessKeyId: "IJTXH751MBXUWMTWM0SF",
//     secretAccessKey: "lfTPRJ2PqAxsp3poTW9YlHktgaL1cFkLup8LidW9",
//   },
//   endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
//   forcePathStyle: true,
// };

// const s3Client = new S3Client(s3Config);

// const BannerImageUpload = ({
//   fileType,
//   value,
//   imageType,
//   btnName,
//   onChange,
//   size = 5,
//   isDimension = false,
//   imageName,
//   ...props
// }) => {
//   const [file, setFile] = useState([]);



//   const uploadFileToS3 = async (file, bucketName) => {
//     const key = "plaint/" + imageType + "/" + file.name
//     const params = {
//       Bucket: bucketName,
//       Key: key,
//       Body: file,
//     };

//     const upload = new Upload({
//       client: s3Client,
//       params: params,
//     });

//     try {
//       const data = await upload.done();
//       console.log(data)
//       return data;
//     } catch (err) {
//       throw err;
//     }
//   };




//   const handleImgChange = async (event) => {
//     const { file } = event;
//     setFile([file]);
//     try {
//       const data = await uploadFileToS3(file, 'invent-colab-obj-bucket');
//       const fileData = {
//         uid: file.uid,
//         name: file.name,
//         status: 'done',
//         url: data.Location,
//         thumbUrl: data.Location,
//       };
//       setFile([fileData]);
//       if (onChange) {
//         onChange([fileData]);
//       }
//       //   setFile([fileData]);
//       console.log(data);
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   const checkImageDimensions = (file) => {
//     return new Promise((resolve, reject) => {
//       const img = document.createElement("img");

//       img.onload = () => {
//         if(imageName == "appImage"){
//           if ((img.width >= 400 || img.width <= 700) && (img.width >= 150 || img.width <= 350)) {
//             resolve();
//           } else {
//             reject(
//               `Please upload an image with dimensions (400-700) x (150-350). uploaded image is ${img.width} X ${img.height}`,
//             );
//           }

//         }else if(imageName == "webImage"){
//           if ((img.width >= 1200 || img.width <= 1500) && (img.width >= 600 || img.width <= 900)) {
//             resolve();
//           } else {
//             reject(
//               `Please upload an image with dimensions (1200-1500) x (600-900). uploaded image is ${img.width} X ${img.height}`,
//             );
//           }
//         }  
//       };

//       img.src = URL.createObjectURL(file);
//     });
//   };

//   const beforeUpload = async (file) => {
//     try {
//       if (fileType.includes(file.type)) {
//       } else {
//         ShowToast("File format is not correct", Severty.ERROR);
//         return false;
//       }
//       const isLt2M = file.size / 1024 / 1024 < size;

//       if (!isLt2M) {
//         ShowToast(`Image must be smaller than ${size} MB!`, Severty.ERROR);
//         return false;
//       }
//       isDimension && (await checkImageDimensions(file));
//       return true;
//     } catch (err) {
//       ShowToast(err, Severty.ERROR);
//       return false;
//     }
//   };

//   const onRemove = () => {
//     setFile([]);
//     if (onChange) {
//       onChange([]);
//     }
//   };

//   useEffect(() => {
//     if (!value) setFile([]);
//   }, [value]);

//   return (
//     <Upload
//       listType="picture"
//       maxCount={1}
//       beforeUpload={beforeUpload}
//       customRequest={handleImgChange}
//       onRemove={onRemove}
//       fileList={file}
//       {...props}
//     >
//       {file && file.length > 0 && file !== "" ? null : (
//         <Button icon={<UploadOutlined />}>
//           {" "}
//           {btnName ? `Upload ${btnName}` : ""}
//         </Button>
//       )}
//     </Upload>
//   );
// };

// export default BannerImageUpload;




import { Button, message, Upload as UploadAntd, Image } from "antd";
import React, { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { uploadFile } from "react-s3";
// import { s3Config } from "../config/s3Config";
import { ShowToast, Severty } from "../helper/toast";
import { getFileExtension } from "../helper/functions";
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import lang from "../helper/langHelper";

const s3Config = {
  region: 'us-east-1',
  credentials: {
    accessKeyId: "IJTXH751MBXUWMTWM0SF",
    secretAccessKey: "lfTPRJ2PqAxsp3poTW9YlHktgaL1cFkLup8LidW9",
  },
  endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/",
  forcePathStyle: true,
};


const s3Client = new S3Client(s3Config);
const SingleImageUpload = ({
  fileType,
  value,
  imageType,
  btnName,
  onChange,
  size = 5,
  isDimension = false,
  imageName,
  ...props
}) => {
  const [file, setFile] = useState([]);

 

  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");

      img.onload = () => {
        console.log(img.width, "img.width", "+++++" ,img.height , "img.height" ,imageName, "imageName" )
        if(imageName == "appImage"){
          if ((img.width >= 400 && img.width <= 700) && (img.height >= 150 && img.height <= 350)) {
            resolve();
          } else {
            reject(
              `Please upload an image with dimensions (400-700) x (150-350). uploaded image is ${img.width} X ${img.height}`,
            );
          }

        }else if(imageName == "webImage"){
          if ((img.width >= 1200 && img.width <= 1500) && (img.height >= 600 && img.height <= 900)) {
            console.log("resolve")
            resolve();
          } else {
            reject(
              `Please upload an image with dimensions (1200-1500) x (600-900). uploaded image is ${img.width} X ${img.height}`,
            );
          }
        }  
      };

      img.src = URL.createObjectURL(file);
    });
  };

  const beforeUpload = async (file) => {
    try {
      if (fileType.includes(file.type)) {
      } else {
        ShowToast("File format is not correct", Severty.ERROR);
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < size;

      if (!isLt2M) {
        ShowToast(`Image must be smaller than ${size} MB!`, Severty.ERROR);
        return false;
      }
      isDimension && (await checkImageDimensions(file));
      return true;
    } catch (err) {
      ShowToast(err, Severty.ERROR);
      return false;
    }
  };

  const onRemove = () => {
    setFile([]);
    if (onChange) {
      onChange([]);
    }
  };

  useEffect(() => {
    if (!value) setFile([]);
  }, [value]);

  const uploadFileToS3 = async (file, bucketName) => {
    const key = "plaint/" + imageType + "/" + file.name
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file,
    };

    const upload = new Upload({
      client: s3Client,
      params: params,
    });

    try {
      const data = await upload.done();
      console.log(data)
      return data;
    } catch (err) {
      throw err;
    }
  };

  const handleImgChange = async (event) => {
    const { file } = event;
    setFile([file]);
    try {
      const data = await uploadFileToS3(file, 'invent-colab-obj-bucket');
      const fileData = {
        uid: file.uid,
        name: file.name,
        status: 'done',
        url: data.Location,
        thumbUrl: data.Location,
      };
      setFile([fileData]);
      if (onChange) {
        onChange([fileData]);
      }
      //   setFile([fileData]);
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <UploadAntd
      listType="picture"
      maxCount={1}
      beforeUpload={beforeUpload}
      customRequest={handleImgChange}
      onRemove={onRemove}
      fileList={file}
      {...props}
    >
      {file && file.length > 0 && file !== "" ? null : (
        <Button icon={<UploadOutlined />}>
          {" "}
          {btnName ? `${lang("Upload")} ${btnName}` : ""}
        </Button>
      )}
    </UploadAntd>
  );
};

export default SingleImageUpload;

