let appMode = process.env.REACT_APP_ENV;
let ASSET_URL =
  "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/";
let URL;

console.log("appMode", appMode);

// 3.20.147.34

if (appMode === "development") {
  URL = "https://backend.planit-co.com/api/";
  // URL = "http://localhost:8900/api/";
} else {
  URL = "https://backend.planit-co.com/api/";
  // URL = "http://localhost:7900/api/";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,
  dashboard: "admin/dashboard",

  listOrder: "admin/order/list",
  viewOrder: "admin/order/view",
  statusOrder: "admin/status",

  listTransaction: "admin/transaction/list",
  viewTransaction: "admin/transaction/view",

  // Auth API
  logout: "admin/auth/logout",
  login: "admin/auth/login",

  profile: "admin/auth/get-profile",
  updateProfile: "admin/auth/update-profile",
  changePassword: "admin/auth/change-password",
  updateAppSetting: "admin/auth/update-app-setting",

  forgotPassword: "admin/auth/forgot-password",
  verifyOTP: "admin/auth/verify-otp",
  resetPassword: "admin/auth/reset-password",

  // Customer APIs
  listCustomer: "admin/customer",
  addEditCustomer: "admin/customer",
  deleteCustomer: "admin/delete",
  statusCustomer: "admin/customer/status",
  viewCustomer: "admin/customer/view",
  importCustomer: "admin/customer/import-file",

  //invite Tempalte
  viewInvite: "admin/invite-template/view",
  listInvite: "admin/invite-template",

  // SubAdmin APIs
  subAdmin: "admin/sub-admin",
  addEditSubAdmin: "admin/sub-admin",
  statusSubAdmin: "admin/sub-admin/status",
  viewSubAdmin: "admin/sub-admin/view",
  getModule: "admin/sub-admin/module-list",
  addPermission: "admin/sub-admin/add-permission",

  // Role APIs
  role: "admin/role",
  statusRole: "admin/role/status",

  // Profile APIS
  listProfile: "/admin/provider-profile",
  statusProfile: "/admin/provider-profile/status",

  //collector
  collector: "admin/collector",
  location: "admin/service-location",

  // driver APIs
  driver: "admin/driver",
  importDealer: "admin/dealer/import-file",

  checkCode: "admin/discount/check-code",
  discount: "admin/discount",
  revenue: "admin/revenue",
  report: "admin/report",
  request: "admin/request",

  //bannner API
  banner: "admin/banner",
  statusBanner: "admin/banner/status",
  history: "admin/delivery-history",
  checkOrder: "admin/banner/check-order",

  // Content APIs
  content: "admin/content",
  notification: "admin/notification",
  notificationUser: "admin/notification/user-notification",
  rating: "admin/rating",

  finance: "admin/finance",

  // EmailTemplate APIs
  listEmailTemplate: "admin/email-template/list",
  addEditEmailTemplate: "admin/email-template/add-edit",
  statusEmailTemplate: "admin/email-template/status",
  viewEmailTemplate: "admin/email-template/view",

  // Blog APIs
  listBlog: "admin/blog",
  addEditBlog: "admin/blog",
  statusBlog: "admin/blog/status",
  viewBlog: "admin/blog/view",

  // Testimonial APIs
  listTestimonial: "admin/testimonial",
  statusTestimonial: "admin/testimonial/status",
  viewTestimonial: "admin/testimonial/view",

  //category
  listCategory: "admin/category",
  statusCategory: "admin/category/status",

  // sub category
  listSubCategory: "admin/sub-category",
  statusSubCategory: "admin/sub-category/status",

  //event Types
  listEventType: "admin/event-type",
  statusEventType: "admin/event-type/status",

  //Services
  listService: "admin/service",
  statusService: "admin/service/status",

  //attribute
  listAttribute: "admin/attribute",
  statusAttribute: "admin/attribute/status",
  listAttributePerCategory: "admin/attribute/view",

  //quote-template
  listQuote: "admin/quote",
  statusQuote: "admin/quote/status",
  listQuoteReply: "admin/quote-reply",

  replyQuoteList: "admin/quote/reply-list",
  requestQuoteList: "admin/quote/request-list",

  //Vendor apis
  providerList: "admin/restaurant",
  getProvider: "admin/restaurant/get-provider",
  changeSelectedCategory: "admin/restaurant/provider-category-select",
  updateProviderCategory: "admin/restaurant/provider-category-update",

  getAppSetting: "common/app-setting",

  // Size APIs
  size: "admin/size",

  //order
  order: "admin/order",

  //log
  log: "admin/activity-log",
  subAdminChangeLog: "admin/change-log",
  reportList: "admin/report",
  supportList: "admin/support",

  // delivery charge
  deliveryCharge: "admin/delivery-charge",

  // packages Api's
  packages: "admin/restaurant/get-packages",
  providerServices: "admin/restaurant/get-services",

  listProviderPkg: "admin/provider-package",
  packagesAddEdit: "admin/provider-package/addEdit",
  pkgService: "admin/provider-package/provider-pkg-service",

  // provider services
  listProviderService: "admin/provider-service",
  addEditProviderService: "admin/provider-service/addEdit",

  common: {
    services: "common/services-by-category",
    categories: "categories",
    subCategories: "sub-category",
    country: "common/country",
    city: "common/city/",
    attributesByServiceCategory: "common/attribute",
    attributeByCategory: "common/attribute-by-category",
  },

  //Common apis
  adminCommon: "admin/common",
  allCategory: "admin/common/categories",
  allSubCategory: "admin/common/sub-category",
  allRole: "admin/common/role",
  allSubAdmin: "admin/common/sub-admin",
  allServices: "admin/common/services",
  Services: "admin/common/all-services",
  allProivder: "admin/common/provider",
  allAttribute: "admin/common/attribute",
  allAttributeByCategory: "admin/common/attribute-by-category",
  allEventType: "admin/common/event-type",

  // Auth API
  logout: "admin/auth/logout",
  signUp: "vendor/auth/sign-up",
};

export default apiPath;
